const App = {
  SetBackground: () => {
    $("[setBackground]").each(function () {
      var background = $(this).attr("setBackground");
      $(this).css({
        "background-image": "url(" + background + ")",
        "background-size": "cover",
        "background-position": "center center",
      });
    });
    $("[setBackgroundRepeat]").each(function () {
      var background = $(this).attr("setBackgroundRepeat");
      $(this).css({
        "background-image": "url(" + background + ")",
        "background-repeat": "repeat",
      });
    });
  },
  EqualHeightElement: (el) => {
    let height = 0;
    let thisHeight = 0;
    $(el).each(function () {
      thisHeight = $(this).height();
      if (thisHeight > height) {
        height = thisHeight;
      }
    });
    $(el).height(height);
  },
  ScrollTo: (y) => {
    $("html, body").animate(
      {
        scrollTop: y,
      },
      1000
    );
  },
  Init: () => {
    App.ScrollTo();
    App.SetBackground();
  },
};

function InitSlider() {
  var heroBanner = new Swiper(".hero-banner .swiper-container", {
    speed: 1500,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },

    autoplay: {
      delay: 7000,
    },
    navigation: {
      clickable: !0,
      nextEl: ".hero-banner .swiper-next",
      prevEl: ".hero-banner .swiper-prev",
    },
    pagination: {
      el: ".hero-banner .swiper-pagination",
      clickable: !0,
      type: "bullets",
    },
  });
  var videoSlide = new Swiper(".video-slider .swiper-container", {
    speed: 1500,
    spaceBetween: 20,
    autoplay: {
      delay: 7000,
    },
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      clickable: !0,
      nextEl: ".video-slider .swiper-next",
      prevEl: ".video-slider .swiper-prev",
    },
    pagination: {
      el: ".video-slider .swiper-pagination",
      clickable: !0,
      type: "bullets",
    },
  });
  var campaignsindivhafSlide = new Swiper(
    ".campaigns-indiv-haf-2 .swiper-container",
    {
      speed: 1500,
      spaceBetween: 20,
      autoplay: {
        delay: 7000,
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        clickable: !0,
        nextEl: ".campaigns-indiv-haf-2 .swiper-next",
        prevEl: ".campaigns-indiv-haf-2 .swiper-prev",
      },
    }
  );

  var latestCampaignsSlide = new Swiper(".latest-campaigns .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 37,
    autoplay: {
      delay: 7000,
    },
    observer: true,
    observeParents: true,
    navigation: {
      clickable: !0,
      nextEl: ".latest-campaigns .swiper-next",
      prevEl: ".latest-campaigns .swiper-prev",
    },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 1,
      },
    },
  });
  var recommendedCampaignsSlide = new Swiper(
    ".recommended-campaigns .swiper-container",
    {
      slidesPerView: 3,
      spaceBetween: 37,
      autoplay: {
        delay: 7000,
      },
      observer: true,
      observeParents: true,
      navigation: {
        clickable: !0,
        nextEl: ".recommended-campaigns .swiper-next",
        prevEl: ".recommended-campaigns .swiper-prev",
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        576: {
          slidesPerView: 1,
        },
      },
    }
  );
  var recommendedactivitiesSlide = new Swiper(
    ".recommended-activities .swiper-container",
    {
      slidesPerView: 3,
      spaceBetween: 37,
      autoplay: {
        delay: 7000,
      },
      observer: true,
      observeParents: true,
      navigation: {
        clickable: !0,
        nextEl: ".recommended-activities .swiper-next",
        prevEl: ".recommended-activities .swiper-prev",
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        576: {
          slidesPerView: 1,
        },
      },
    }
  );

  var peopleAlsoViewSlide = new Swiper(
    ".people-also-viewed .swiper-container",
    {
      slidesPerView: 3,
      spaceBetween: 37,
      autoplay: {
        delay: 7000,
      },
      observer: true,
      observeParents: true,
      navigation: {
        clickable: !0,
        nextEl: ".people-also-viewed .swiper-next",
        prevEl: ".people-also-viewed .swiper-prev",
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        576: {
          slidesPerView: 1.5,
        },
      },
    }
  );
  var interestedInSlide = new Swiper(".interested-in .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 37,
    autoplay: {
      delay: 7000,
    },
    observer: true,
    observeParents: true,
    navigation: {
      clickable: !0,
      nextEl: ".interested-in .swiper-next",
      prevEl: ".interested-in .swiper-prev",
    },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 1,
      },
    },
  });
  if ($(".video-gallery").length) {
    var swiper = new Swiper(".my-swiper2", {
      spaceBetween: 20,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        575: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
      },
    });
    var swiper2 = new Swiper(".my-swiper", {
      spaceBetween: 24,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });
  }
  var trendingCampaignsSlide = new Swiper(
    ".trending-campaigns .swiper-container",
    {
      slidesPerView: 3,
      spaceBetween: 37,
      autoplay: {
        delay: 7000,
      },
      observer: true,
      observeParents: true,
      navigation: {
        clickable: !0,
        nextEl: ".trending-campaigns .swiper-next",
        prevEl: ".trending-campaigns .swiper-prev",
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        576: {
          slidesPerView: 1,
        },
      },
    }
  );

  if ($(".service-details").length) {
    var swiper = new Swiper(".service-details .my-swiper", {
      spaceBetween: 30,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
      breakpoints: {
        768: {
          spaceBetween: 15,
        },
      },
    });
    var swiper2 = new Swiper(".service-details .my-swiper2", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".service-details .swiper-next",
        prevEl: ".service-details .swiper-prev",
      },
      thumbs: {
        swiper: swiper,
      },
      pagination: {
        el: ".service-details .swiper-pagination",
        clickable: !0,
        type: "bullets",
      },
    });
  }
  if ($(".cbpm").length) {
    var numSlick = 0;
    $(".cbpm .slick-container").each(function () {
      numSlick++;
      $(this)
        .addClass("slider-" + numSlick)
        .slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
          prevArrow: $(this).parents(".tab-content").find(".swiper-prev"),
          nextArrow: $(this).parents(".tab-content").find(".swiper-next"),
        });
    });
  }
  if ($(".programmes-slider").length) {
    var numSlick = 0;
    $(".programmes-slider .slick-container").each(function () {
      numSlick++;
      $(this)
        .addClass("slider-" + numSlick)
        .slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        });
    });
  }
  if ($(".abpm").length) {
    var numSlick = 0;
    $(".abpm .slick-container").each(function () {
      numSlick++;
      $(this)
        .addClass("slider-" + numSlick)
        .slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
          prevArrow: $(this).parents(".tab-content").find(".swiper-prev"),
          nextArrow: $(this).parents(".tab-content").find(".swiper-next"),
        });
    });
  }
  if ($(".upload-multiple").length) {
    var numSlick = 0;
    $(".upload-multiple .list-image").each(function () {
      numSlick++;
      $(this)
        .addClass("slider-" + numSlick)
        .slick({
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: false,
          focusOnSelect: false,
          responsive: [
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
          ],

          prevArrow: $(this).parents(".upload-multiple").find(".btn-prev"),
          nextArrow: $(this).parents(".upload-multiple").find(".btn-next"),
        });
    });
  }
}

function Headers() {
  $(document).on("click", ".toggle-btn", function () {
    $(this).toggleClass("active");
    $(".mobile-nav-inner").toggleClass("active");
  });
  $(document).on(
    "click",
    ".mobile-nav-inner li.has-dropdown > .icon-dropdown",
    function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(this).parents(".has-dropdown").removeClass("active");
        $(this).next().slideUp();
      } else {
        $(".header .nav-sub").slideUp();
        $(".icon-dropdown").removeClass("active");
        $(".has-dropdown").removeClass("active");
        $(this).toggleClass("active").next().slideDown();
        $(this).parents(".has-dropdown").toggleClass("active");
      }
    }
  );
  // $(window).on("resize", function () {
  //   $("header .mobile-nav-inner").removeClass("active");
  //   $("body").removeClass("overflow-hidden");
  //   $(".toggle-btn").removeClass("active");
  // });
  $(document).on("click", ".sidebar .head", function () {
    $(this).toggleClass("active");
    $(".sidebar .menu").slideToggle();
  });
  $(document).on("click", ".table .arrow", function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).parents("tbody").find(".more-content").slideUp(300);
    } else {
      $(".table .more-content").slideUp(300);
      $(".table .arrow").removeClass("active");
      $(this)
        .toggleClass("active")
        .parents("tbody")
        .find(".more-content")
        .slideDown(300);
    }
  });
}
//mapping
function mappings() {
  if ($(".header").length) {
    var moveSearch = new MappingListener({
      selector: ".header .search",
      mobileWrapper: ".mobile-nav-inner",
      mobileMethod: "appendTo",
      desktopWrapper: ".header .toggle-btn",
      desktopMethod: "insertBefore",
      breakpoint: 1200,
    }).watch();
    var movetop = new MappingListener({
      selector: ".header .top-wrap",
      mobileWrapper: ".mobile-nav-inner",
      mobileMethod: "appendTo",
      desktopWrapper: ".header .right-box .bottom-wrap",
      desktopMethod: "insertBefore",
      breakpoint: 1200,
    }).watch();
    var moveNav = new MappingListener({
      selector: ".header .menu",
      mobileWrapper: ".mobile-nav-inner",
      mobileMethod: "appendTo",
      desktopWrapper: ".header .bottom-wrap .account",
      desktopMethod: "insertBefore",
      breakpoint: 1200,
    }).watch();
  }
}
function accordion() {
  if ($(".accordions").length) {
    $(".accordion-item.active").find(".accordion-content").show();
    $(".accordion-item.active").next(".next").show();
    $(".accordion-heading").on("click", function () {
      if (!$(this).parent().is(".active")) {
        $(this)
          .parent()
          .toggleClass("active")
          .children(".accordion-content")
          .slideToggle(500)
          .parents(".accordion-item")
          .next(".next")
          .toggle();
        $(this)
          .parent()
          .siblings(".active")
          .removeClass("active")
          .children(".accordion-content")
          .slideToggle(500)
          .parents(".accordion-item")
          .next(".next")
          .hide();
      } else {
        $(this).parent().toggleClass("active");
        $(this).next().slideToggle(500);
        $(this).parents(".accordion-item").next(".next").toggle();
      }
      $(".list-image").slick("setPosition", 0);
    });
    $(".accordions")
      .find(".next")
      .on("click", function () {
        $(this).hide();
        $(".accordion-item").removeClass("active");
        $(".accordion-content").hide();
        $(".list-image").slick("setPosition", 0);
        $(this).next().find(".accordion-content").slideToggle(500);
        $(this).next().toggleClass("active");
        $(this).next().next().show();
        $(".list-image").slick("setPosition", 0);
      });
  }
}

function jarallax() {
  $(".jarallax").jarallax({
    speed: 0.2,
    disableParallax: / iPad | iPhone | iPod | Android /,
    disableVideo: / iPad | iPhone | iPod | Android /,
  });
}

function myTabs() {
  $(".my-tabs").each(function () {
    var $this = $(this);
    $this.find(".tabs-nav li:first").addClass("active");
    $this.find(".tab-content:first").show();
    $this.find(".tabs-nav li").click(function () {
      var corresponding = $(this).data("tab");
      $this.find(".tab-content").hide();
      $this.find(".tab-content." + corresponding).show();
      $this.find(".tabs-nav li").removeClass("active");
      $(this).addClass("active");
      $(".slick-container").slick("setPosition", 0);
    });
  });
  $(".my-child-tabs").each(function () {
    var childTabs = $(this);
    childTabs.find(".child-tabs-nav li:first").addClass("active");
    childTabs.find(".child-tab-content:first").show();
    childTabs.find(".child-tabs-nav li").click(function () {
      var corresponding = $(this).data("tab");
      childTabs.find(".child-tab-content").hide();
      childTabs.find(".child-tab-content." + corresponding).show();
      childTabs.find(".child-tabs-nav li").removeClass("active");
      $(this).addClass("active");
    });
  });
  $(document).on("click", ".mobile-tabs-nav", function () {
    $(this).toggleClass("active");
    $(".tabs-nav").toggle();
  });
}
function selectUi() {
  $(".dropdown").on("click", ".dropdown-select", function () {
    if ($(this).parents(".dropdown").hasClass("open")) {
      $(this).parents(".dropdown").removeClass("open");
      $(this).parents("td").removeClass("active");
      $(this).siblings(".dropdown-list").hide();
    } else {
      $(".dropdown").removeClass("open");
      $(this).parents(".dropdown").addClass("open");
      $(this).parents("td").addClass("active");
      $(".dropdown-list").hide();
      $(this).siblings(".dropdown-list").show();
    }
  });
  var allOptions = $(".dropdown-list").children(".dropdown-item");
  $(".dropdown-list").on("click", ".dropdown-item", function () {
    allOptions.removeClass("selected");
    $(this).addClass("selected");
    $(this)
      .parents(".dropdown:not(.actions-dropdown)")
      .find(".dropdown-selected")
      .html($(this).html());

    $(this).parents(".dropdown-list").toggle();
    $(this).parents(".dropdown").toggleClass("open");
    $(this).parents("td").toggleClass("active");
    $("input#inputInit").val($(this).data("val"));
  });
  var $menu = $(".dropdown");

  $(document).mouseup(function (e) {
    if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
      $menu.removeClass("open");
      $menu.parents("td").removeClass("active");
      $menu.find(".dropdown-list").hide();
    }
  });
}

function checkWidth() {
  var $window = $(window);
  var windowsize = $window.width();
  if (windowsize >= 1200) {
    $(".header").find(".nav-sub").removeAttr("style");
  }
  if (windowsize >= 768) {
    $(".tabs-nav").removeAttr("style");
  }
  $window.on("load resize", function () {
    var HH = $(".header").height();
    var WH = $window.height();
    $("#content").css("padding-top", HH + "px");
    $(".hero-banner .swiper-slide").css("height", WH - HH + "px");
    $(".list-image").slick("setPosition", 0);
  });
  if (windowsize >= 768) {
    $(".sidebar").removeAttr("style");
  }
}

function searchIcon() {
  $(".header").on("click", ".search-toggle", function () {
    $(this).toggleClass("active");
    $(".searchbox").toggle();
  });
}
function sidebarDropdown() {
  //mega menu
  $(document).on("click", ".sidebar .sidebar-text", function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).siblings(".menu,.filter-list").slideUp(200);
    } else {
      $(".sidebar .sidebar-text").removeClass("active");
      $(this).addClass("active");
      $(".sidebar .menu,.filter-list").slideUp(200);
      $(this).siblings(".menu,.filter-list").slideDown(200);
    }
  });
  $(document).on("click", ".mb-filter", function () {
    $(this).toggleClass("active");
    $(".sidebar").toggle();
  });
}
function countUp() {
  if ($(".statistics").length) {
    var options = {
      useEasing: true,
      useGrouping: true,
      separator: ",",
      decimal: ".",
      prefix: "",
      suffix: "",
    };
    var counts = [];
    $(".counter-1").each(function () {
      var num = $(this).attr("data-count"); //end count
      var nuen = $(this).text();
      if (nuen === "") {
        nuen = 0;
      }

      counts.push(new CountUp(this, nuen, num, 0, 3, options));
    });
    if ($(".statistics-1").length) {
      var waypoint1 = new Waypoint({
        element: document.getElementsByClassName("statistics-1"),
        handler: function (direction) {
          if (direction == "up") {
            for (var i = 0; i < counts.length; i++) {
              counts[i].reset();
            }
          } else {
            for (var i = 0; i < counts.length; i++) {
              counts[i].start();
            }
          }
        },
        offset: "80%",
      });
    }
  }
}
function progress() {
  if ($(".progress").length) {
    $(".progress").each(function () {
      var progressDone = $(this).find(".progress-done");
      var total = $(this).parent().find(".total").text().replace(/,/g, "");
      var donating = progressDone.attr("data-donate");
      var progressResult = (donating / total) * 100 + "%";
      $(this)
        .parent()
        .find(".donating")
        .html(" " + donating.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
      progressDone.css({
        width: "" + progressResult + "",
        opacity: "1",
      });
      $(this)
        .parent()
        .find(".donated")
        .html(progressResult + " Donated");
    });
  }
  if ($(".slots").length) {
    $(".slots").each(function () {
      var slotsDone = $(this).find(".slots-done");
      var total = $(this).parent().find(".total").text().replace(/,/g, "");
      var slotsFilled = slotsDone.attr("data-value");
      var slotsResult = (slotsFilled / total) * 100 + "%";
      $(this)
        .parent()
        .find(".slots-filled")
        .html(" " + slotsFilled);
      slotsDone.css({
        width: "" + slotsResult + "",
        opacity: "1",
      });
      $(this)
        .parent()
        .find(".done")
        .html(slotsResult + " Completed");
    });
  }
  if ($(".step-progress").length) {
    $(".step-progress").each(function () {
      var progressDone = $(this).find(".progress-done");
      var total = $(this).find(".total").text().replace(/,/g, "");
      var stepValue = progressDone.attr("data-value");
      var stepResult = (stepValue / total) * 100 + "%";
      $(this)
        .find(".stepping")
        .html(" " + stepValue);
      progressDone.css({
        width: "" + stepResult + "",
        opacity: "1",
      });
      $(this).find(".step-result").html(stepResult);
    });
  }
}
function replaceSvg() {
  $("img.svg").each(function () {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");

    $.get(
      imgURL,
      function (data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find("svg");

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr("xmlns:a");

        // Replace image with new SVG
        $img.replaceWith($svg);
      },
      "xml"
    );
  });
}
function uploadPhoto() {
  ////////upload images

  var readURL = function (input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      var div_id = $(input).attr("set-to");
      reader.onload = function (e) {
        $("#" + div_id).attr("src", e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  };
  $(".file-upload").on("change", function () {
    readURL(this);
    var filename = $(this).val();
    $(this).siblings(".file-upload-name").html(filename);
    var $this = $(this);
    // if (filename != "") {
    //   setTimeout(function () {
    //     $this.siblings(".upload-button").text("Loading..").css("color", "#999");
    //   }, 600);
    //   setTimeout(function () {
    //     $this
    //       .siblings(".upload-button")
    //       .text("Sucessesfully")
    //       .css("color", "green");
    //   }, 1600);
    // }
  });
  $(".upload-button").on("click", function () {
    $(this).siblings(".file-upload").click();
  });
}
//dashboard
function dashboard() {
  $(".aside-toggle-btn").click(function () {
    $(".aside-nav,.dashboard-main").toggleClass("active");
  });
  $(".aside-nav-ul .nav-link").click(function () {
    $(this).find(".dropdown-caret-svg").toggleClass("rotate-svg");
  });
  $(".aside-nav-ul .nav-link").click(function () {
    $(".aside-nav-ul .nav-link").removeClass("active");
    $(this).addClass("active");
  });
  $(".aside-nav-ul").on("click", ".nav-item", function () {
    const liElement = $(this);
    liElement
      .siblings()
      .children()
      .children(".dropdown-caret-svg")
      .removeClass("rotate-svg");
    liElement.siblings().children(".aside-dropdown-menu").removeClass("show");
    if ($(this).hasClass("active")) {
      liElement.siblings().children(".aside-dropdown-menu").addClass("show");
    }
  });
}
//dashboard
function tableScroll() {
  $(".table-responsive").each(function () {
    var $elem = $(this);
    var tblw = $elem.find("table").outerWidth();
    var width = $elem.outerWidth();
    if (tblw > width) {
      $elem.addClass("overlay");
    } else {
      $elem.removeClass("overlay");
    }
    $(".table-responsive > div").scroll(function () {
      var newScrollLeft = $(this).scrollLeft(),
        scrollWidth = $(this).get(0).scrollWidth,
        nWidth = $(this).outerWidth();
      if (scrollWidth - newScrollLeft == nWidth) {
        // alert("right end");
        $(this).parents(".table-responsive").addClass("left");
      } else {
        $(this).parents(".table-responsive").addClass("overlay");
      }
      if (newScrollLeft === 0) {
        // alert("left end");
        $(this).parents(".table-responsive").removeClass("left");
      }
    });
  });
}

$(document).ready(function () {
  App.Init();
  InitSlider();
  replaceSvg();
  Headers();
  mappings();
  $(window).resize(function () {
    tableScroll();
    checkWidth();
  });
  selectUi();
  accordion();
  sidebarDropdown();
  searchIcon();
  countUp();
  progress();
  tableScroll();
  jarallax();
  uploadPhoto();
  myTabs();
  checkWidth();
  dashboard();
  $(".less-more-btn").click(function () {
    $(this).parents(".article").find(".more-content").toggle();
    $(this).parents(".article").toggleClass("active");
    if ($(this).find(".txt").text() == "VIEW MORE") {
      $(this).find(".txt").text("VIEW LESS");
      $(this).find(".icon").addClass("up");
    } else {
      $(this).find(".txt").text("VIEW MORE");
      $(this).find(".icon").removeClass("up");
    }
  });
  $("[data-fancybox]").fancybox({
    closeExisting: true,
    loop: true,
    touch: false,
  });
  $(".login-popup .popup-close").click(function () {
    $(".login-popup").fadeOut(300);
  });
  $(".login-popup .popup-overlay").click(function () {
    $(".login-popup").fadeOut(300);
  });
  $(".toggle-pass").click(function () {
    $(this).toggleClass("active");
    var input = $(this).parents(".form-group").find("input");
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });
  $(".data-table").each(function () {
    var table = $(this).DataTable({
      select: false,
      searching: false,
      bLengthChange: false,
      info: false,

      language: {
        oPaginate: {
          sNext:
            '<img src="./img/common-icons/double-chevron-right.png", alt="arrow"></img>',
          sPrevious:
            '<img src="./img/common-icons/double-chevron-left.png", alt="arrow"></img>',
          sFirst: '<i class="fa fa-step-backward"></i>',
          sLast: '<i class="fa fa-step-forward"></i>',
        },
      },
      initComplete: function (settings, json) {
        $(".dataTables_paginate").each(function () {
          $(this).appendTo($(this).parents(".table-responsive")); //jQuery for moving elements around
        });
      },
    }); //End of create main table
  });
  //tooltip
  $(".tooltip").each(function () {
    $(this)
      .find(".tooltip-toggle")
      .on("click", function () {
        $(this).siblings(".tooltip-content").toggle();
      });
    //edit
    $(".tooltip input").click(function () {
      var ttl = $(this).data("tooltip");
      if ($(this).is(":checked")) {
        $(this).parents(".tooltip").find(".tooltip-content").hide();
        $(this)
          .parents(".tooltip")
          .find(".tooltip-content." + ttl)
          .show();
      } else {
        $(this).parents(".tooltip").find(".tooltip-content").hide();
      }
    });
  });
  //editor

  var allEditors = document.querySelectorAll(".editor");
  for (var i = 0; i < allEditors.length; ++i) {
    ClassicEditor.create(allEditors[i]);
  }
  $(".upload-2 .remove").each(function () {
    $(this).on("click", function () {
      $(this).siblings("img").attr("src", "./img/placeholder.png");
      $(this)
        .parents(".upload-2")
        .find(".file-upload-name")
        .html("No File Chosen");
    });
  });
  //gallery image
  function imgName() {
    $(".upload-multiple .upload-button").each(function () {
      var lk = $(this).data("src");
      var cellTexts = $(".gallery-popup" + lk)
        .find("input:checked")
        .siblings("label")
        .find(".title")
        .map(function () {
          return $(this).text();
        })
        .get();
      var allText = cellTexts.join(",");
      $(this)
        .parents(".upload-multiple")
        .find(".file-upload-name")
        .html(allText);
    });
  }
  imgName();
  $(".list-image .image").addClass("hide");
  $(".check-box:checked").each(function () {
    $("#" + $(this).attr("data-image")).removeClass("hide");
  });
  $(".gallery-popup .check-box").change(function () {
    if ($(".check-box:checked").length == 0) {
      $(".list-image .image").addClass("hide");
    } else {
      $(".list-image .image").addClass("hide");
      $(".check-box:checked").each(function () {
        $("#" + $(this).attr("data-image")).removeClass("hide");
      });
    }
    $(".list-image").slick("setPosition", 0);
    imgName();
  });
  $(".upload-multiple .remove").each(function () {
    $(this).on("click", function () {
      $(this).parent().addClass("hide");
      var id = $(this).parent().attr("id");
      $(".gallery-popup")
        .find("[data-image='" + id + "']")
        .prop("checked", false);
      $(".list-image").slick("setPosition", 0);
      imgName();
    });
  });
  if ($(".box-chart").length) {
    var myChart = echarts.init(document.getElementById("chart"));
    function format(data) {
      var data = parseFloat(data);
      return data.toLocaleString({ style: "currency", currency: "TRY" });
    }
    // Specify the configuration items and data for the chart
    var option = {
      title: {
        text: "Donations For Financial Year 2021",
        textStyle: {
          color: "#452A50",
          fontStyle: "normal",
          fontWeight: "700",
          fontFamily: "sans-serif",
          fontSize: 15,
        },
      },
      xAxis: {
        data: [
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
        axisLabel: {
          color: "#43425D",
          opacity: "0.5",
        },
        axisTick: {
          show: false,
        },

        axisLine: {
          lineStyle: {
            color: "#EAF0F4",
            width: 2,
          },
        },
      },
      yAxis: {
        type: "value",
        min: 0,
        max: 15000,
        position: "left",
        axisLabel: {
          // formatter: "${value}k",
          color: "#43425D",
          formatter: function (value, index) {
            var value;
            if (value >= 1000) {
              value = value / 1000 + "k";
            } else if (value < 1000) {
              value = value;
            }
            return value;
          },
        },
        splitLine: {
          lineStyle: {
            color: "#EAF0F4",
            width: 2,
          },
        },
      },
      grid: {
        x: 40,
        y: 50,
        x2: 0,
        y2: 30,
      },
      series: [
        {
          // name: "sales",
          // barWidth: 26,
          type: "bar",
          data: [21238, 6238, 12839, 1583],
          itemStyle: {
            barBorderRadius: [4, 4, 0, 0],
            color: "#F5E4F0",
          },
          emphasis: {
            itemStyle: {
              color: "#F5E4F0",
            },
          },
        },
      ],

      tooltip: {
        show: true,
        trigger: "item",
        padding: 5,
        backgroundColor: "none",
        textStyle: {
          color: "#43425D",
          fontStyle: "normal",
          fontWeight: "400",
          textShadow: false,
          fontSize: 15,
        },
        axisPointer: {
          shadownStyle: {
            color: "transparent",
            shawdownBlur: "none",
            ShadownColor: "transparent",
          },
        },
        formatter: function (params) {
          var cl = format(params.value);
          return `$${cl}`;
        },
      },
    };
    myChart.setOption(option);
    window.onresize = function () {
      myChart.resize();
    };
  }
});
